/* ProgressSteps.css */
body {
  background: #F0F2F5;
  font-family: Arial,
    Helvetica,
    sans-serif !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.main-container {
  width: 100%;
  margin: 0 auto;
}

.step-container {
  display: flex;
  justify-content: space-between;
  position: relative;
  width: 75%;
  margin: 0px auto;
  margin-top: 70px;
}

.step-container:before {
  content: '';
  position: absolute;
  border-bottom: 2px dashed #ccc;
  height: 4px;
  width: 100%;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
}

.step-container:after {
  content: '';
  position: absolute;
  background: #4a154b;
  height: 4px;
  width: var(--width);
  top: 50%;
  transition: 0.4s ease;
  transform: translateY(-50%);
  left: 0;
}

.step-wrapper {
  position: relative;
  z-index: 1;
}

.step-style {
  width: 39px;
  height: 39px;
  border-radius: 50%;
  background-color: #ffffff;
  border: 2px solid #9A9A9A;
  transition: 0.4s ease;
  display: flex;
  justify-content: center;
  align-items: center;
}

.step-count {
  font-size: 20px;
  color: #9A9A9A;
  font-weight: 700;
}

.steps-label-container {
  position: absolute;
  top: 66px;
  left: 50%;
  transform: translate(-50%, -50%);
}

.step-label {
  color: #9A9A9A;
  font-size: 14px;
  font-weight: 700;
  line-height: 19.2px;
  text-transform: uppercase;
  white-space: nowrap;
}

.buttons-container {
  display: flex;
  justify-content: space-between;
  margin: 0 -15px;
  margin-top: 100px;
}

.button-styledown {
  border-radius: 5px;
  color: #fff;
  font-size: 14px;
  font-weight: 600;
  line-height: 16.8px;
  letter-spacing: 0.343px;
  text-transform: uppercase;
  padding: 10px 15px;
  background: #3ABD5F;
  border: none;
  text-transform: capitalize;
  display: flex;
}

.button-styledown-border {
  border: 1px solid #3ABD5F;
  padding: 2px 2px;
  border-radius: 5px;
  background: #fff;
}

.button-stylerec {
  border-radius: 5px;
  color: #fff;
  font-size: 14px;
  font-weight: 600;
  line-height: 16.8px;
  letter-spacing: 0.343px;
  text-transform: uppercase;
  padding: 10px 25px;
  background: #3ABD5F;
  border: none;
  text-transform: capitalize;
  display: flex;
}

.button-stylerec-border {
  border: 1px solid #3ABD5F;
  padding: 2px 2px;
  border-radius: 5px;
  background: #fff;
}

.button-stylebac {
  border-radius: 5px;
  color: #fff;
  font-size: 16px;
  font-weight: 600;
  line-height: 16.8px;
  letter-spacing: 0.343px;
  text-transform: uppercase;
  padding: 11px 35px;
  background: #8D8D94;
  box-shadow: 0px 1px 5px 0px rgba(52, 71, 103, 0.15), 0px 3px 1px -2px rgba(52, 71, 103, 0.20), 0px 3px 3px 0px rgba(52, 71, 103, 0.15);
  border: none;
  cursor: pointer;
}

.button-stylepay {
  border-radius: 5px;
  background: linear-gradient(185deg, #49A3F1 0%, #2C85EB 100%);
  box-shadow: 0px 1px 5px 0px rgba(52, 71, 103, 0.15), 0px 3px 1px -2px rgba(52, 71, 103, 0.20), 0px 3px 3px 0px rgba(52, 71, 103, 0.15);
  color: #FFF;
  text-align: center;
  font-size: 16px;
  font-weight: 600;
  line-height: 16.8px;
  letter-spacing: 0.343px;
  text-transform: initial;
  border: none;
  padding: 11px 23px;
  white-space: nowrap;
  float: right;
  cursor: pointer;
}

.button-style:active {
  transform: scale(0.98);
}

.button-style:disabled {
  background: #f3e7f3;
  color: #000000;
  cursor: not-allowed;
}

.check-mark {
  font-size: 26px;
  font-weight: 600;
  color: #FFF;
  -ms-transform: scaleX(-1) rotate(-46deg);
  /* IE 9 */
  -webkit-transform: scaleX(-1) rotate(-46deg);
  /* Chrome, Safari, Opera */
  transform: scaleX(-1) rotate(-46deg);
  margin-top: -5px;
}

.card-container {
  border-radius: 3px;
  background: #FFF;
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.06), 0px 4px 6px -1px rgba(0, 0, 0, 0.10);
  padding: 25px;
  margin-top: 7em;
}

.billpara {
  color: #344767;
  font-size: 25px;
  font-weight: 700;
  line-height: 27.5px;
}

.fortwoimg {
  display: flex;
  float: right;
  gap: 18px;
}

.radio-tabs-container {
  display: flex;
  flex-direction: row;
  margin-top: 20px;
}

.radio-tab {
  display: inline-flex;
  align-items: center;
  margin-left: 0px;
  margin-right: 10px;
  padding: 12px 26px;
  border-radius: 4px;
  cursor: pointer;
  color: #344767;
  text-align: center;
  font-size: 12px;
  font-weight: 700;
  line-height: 16.8px;
  letter-spacing: 0.343px;
  text-transform: uppercase;
  gap: 10px;
  border-radius: 5px;
  border: 1.5px solid #344767;
  background: #FFF;
}

.radio-tab input[type="radio"] {
  width: 18px;
  height: 18px;
}

.form-control {
  margin-top: 5px;
  line-height: 1.5;
  border-radius: 5px;
  border: 1.55px solid #D0D0D0;
}

.signup-lab,
.expiryMonth {
  color: #344767;
  text-align: center;
  font-size: 14px;
  font-weight: 600;
  line-height: 150%;
}

.valuedo {
  color: #344767;
  font-size: 30px;
  font-weight: 700;
  text-align: center;
  border-bottom: 1px solid #E2E2E2;
  padding-bottom: 20px;
}

.credimage {
  position: relative;
  margin-top: -8em;
}

.credimage1 {
  position: relative;
  margin-top: -8em;
  margin-left: 8px;
}

.aboveone {
  color: #344767;
  font-size: 14px;
  font-weight: 600;
  line-height: 19.2px;
  letter-spacing: 0.129px;
  text-transform: capitalize;
  margin-bottom: 11px;
}

.yrea {
  color: #1D2B36;
  font-size: 16px;
  font-weight: 700;
  line-height: 19.2px;
  letter-spacing: 0.129px;
  text-transform: uppercase;
  margin-bottom: 0;
}

.duplitab tr:nth-child(even) {
  background-color: #fff !important;
}

.duplitab tr {
  border-style: none !important;
  border-color: #fff !important;
}

.duplitabe tr:nth-child(even) {
  background-color: #fff !important;
}

.duplitabe tr {
  border: 1px solid #ccc !important;
}

.fortablepro table tr td:nth-child(1) {
  width: 55%;
  color: #344767;
  font-size: 16px;

}

.fortablepro table tr td:nth-child(2) {

  color: #344767;
  font-size: 14px;
  font-weight: 700;

}

/* 
.fortablepro {
  border-bottom: 1px solid #E2E2E2;
  padding-bottom: 13px;
} */

.inboxs {
  color: #2C85EB;
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 0px;
}

.biicon {
  border-radius: 7px;
  border: 1.5px solid rgba(51, 122, 183, 0.74);
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25);
  padding: 5px 8px;
}

.input-container {
  position: relative;
  display: flex;
  align-items: center;
}

.image-container {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  /* Adjust the distance from the right side */
}

.image-container img {
  width: 25px;
  height: 15px;
}

.form-control::placeholder {
  font-size: 12px;
  color: #64748B;
}

.newsteps {
  font-size: 15px;
  font-weight: 600;
  color: #FFF;
  margin-top: 0px;
}

.expiry::placeholder {
  text-align: center;
  /* Adjust the horizontal alignment as needed */
  vertical-align: middle;
  /* Adjust the vertical alignment as needed */
}

.loginbut {
  float: right;
  border-radius: 5px;
  background-color: #BFCC5C;
  box-shadow: 0px 1px 5px 0px rgba(52, 71, 103, 0.15), 0px 3px 1px -2px rgba(52, 71, 103, 0.20), 0px 3px 3px 0px rgba(52, 71, 103, 0.15);
  padding: 11px 30px;
  border: none;
  color: #FFF;
  text-align: center;
  font-size: 16px;
  font-weight: 600;
  line-height: 16.8px;
  letter-spacing: 0.343px;
  text-transform: uppercase;
  margin-top: 10px;
}

.conformed {
  color: #1D2B36;
  font-size: 16px;
  font-weight: 600;
  line-height: 27.5px;
  text-align: justify;
}

.downconf {
  border-radius: 5px;
  background: #3ABD5F;
  color: #fff;
  border: none;
  text-align: center;
  font-size: 16px;
  font-weight: 700;
  line-height: 16.8px;
  letter-spacing: 0.343px;
  text-transform: capitalize;
  padding: 9px 22px;
}

.fordownbut {
  border-radius: 5px;
  border: 1px solid rgba(58, 189, 95, 0.65);
  padding: 11px 2px;
}

.hide {
  display: none;
}

.choosecard {
  color: #344767;
  font-size: 16px;
  font-weight: 700;
  line-height: 27.5px;
}

.starnum {
  color: #1D2B36;
  font-size: 16px;
  font-weight: 700;
  line-height: 19.2px;
  letter-spacing: 0.129px;
  text-transform: capitalize;
  margin-bottom: 8px;
  margin-top: 11px;
}

.trashstyle {
  border-radius: 2px;
  background: rgba(217, 217, 217, 0.47);
  color: #FF1D37;
  padding: 5px 10px;
  float: right;
  font-size: 17px;
  cursor: pointer;
}

.duplitabe td {
  padding: 15px;
}

.oronetwo {
  border-radius: 5px;
  border: 1px solid #D0D0D0;
  padding: 4px 2px;
  color: #000;
  text-align: center;
  font-size: 16px;
  font-weight: 600;
  line-height: 16.8px;
  letter-spacing: 0.343px;
  text-transform: capitalize;
  margin-bottom: 0;
}

.inv-num,
.inv-dt {
  color: #344767;
  font-weight: 600;
}

.inv-num1,
.inv-dt1 {
  color: #344767;
  }

@media only screen and (min-width: 888px) and (max-width: 1050px) {
  .header-row {
    margin-top: 18px !important;
  }

  .header-address {
    font-size: 14px !important;
    margin-top: 4px !important;
  }

  .toll-btn {
    font-size: 14px !important;
  }

  .tele-icon {
    font-size: 12px !important;
  }
}

@media only screen and (min-width: 590px) and (max-width: 887px) {
  .header {
    margin-top: 6px !important;
    margin-bottom: 6px !important;
  }

  .header-logo {
    width: 6em !important;
  }

  .header-row {
    margin-top: 0px !important;
  }

  .header-address {
    font-size: 15px !important;
    margin-top: 4px !important;
  }

  .toll-btn {
    font-size: 14px !important;
    margin-top: 5px !important;
  }

  .tele-icon {
    font-size: 12px !important;
  }
}

@media only screen and (min-width: 522px) and (max-width: 589px) {
  .header {
    margin-top: 6px !important;
    margin-bottom: 6px !important;
  }

  .header-logo {
    width: 5em !important;
  }

  .header-row {
    margin-top: 0px !important;
  }

  .header-address {
    font-size: 12px !important;
    margin-top: 4px !important;
  }

  .toll-btn {
    font-size: 12px !important;
    margin-top: 5px !important;
  }

  .tele-icon {
    font-size: 10px !important;
  }
}

@media only screen and (min-width: 431px) and (max-width: 521px) {
  .toll-btn {
    margin-top: 12px !important;
  }

  .header-logo {
    width: 5em !important;
    margin-top: 5px;
  }
}

@media only screen and (min-width: 320px) and (max-width: 521px) {
  .header {
    margin-top: 5px !important;
  }

  .header-row {
    margin-top: 0px !important;
  }

  .header-address {
    font-size: 12px !important;
    margin-top: 4px !important;
    margin-bottom: 10px !important;
  }

  .toll-btn {
    font-size: 12px !important;
  }

  .tele-icon {
    font-size: 10px !important;
  }
}

@media only screen and (max-width: 430px) {
  .header-addres {
    display: none;
  }

  .toll-btn {
    margin-top: 4px !important;
  }

  .header-logo {
    width: 4.5em !important;
    margin-top: 0px;
    margin-bottom: 5px;
    margin-left: -20px;
  }
}

@media only screen and (max-width: 374px) {

  .radio-group {
    display: flex !important;
  }

  .radio-text {
    font-size: 10px !important;
    white-space: nowrap !important;
  }

  .radio-btn {
    width: 120px !important;
    height: 35px !important;
  }
}

@media(min-width: 375px) and (max-width: 575px) {
  .radio-text {
    font-size: 12px !important;
    white-space: nowrap !important;
  }

  .radio-btn {
    width: 140px !important;
    height: 35px !important;
  }
}

@media only screen and (max-width: 575px) {

  .header-xs-10 {
    flex: 0 0 auto !important;
    width: 80% !important;
  }

  .header-xs-2 {
    flex: 0 0 auto !important;
    width: 20% !important;
  }
}

@media only screen and (max-width: 575px) {
.expireyear{
  margin-top: 0px !important;
}
}

@media only screen and (max-width: 767px) {
  .backtohome {
   font-size: 13px !important;
   padding: 10px 15px !important;
}
.loginbtnpage {
  font-size: 13px !important;
  padding: 10px 30px !important;
 }
  .ant-radio-wrapper{
    padding: 8px 8px !important;
  }
  .copy-text {
    margin-top: 12px !important;
    margin-bottom: 12px !important;
  }

  .conformed {
    font-size: 14px !important;
  }

  .mbl-res {
    margin-top: 10px !important;
  }

  .button-stylerec {
    padding: 10px 15px;
  }

  .inv-num,
  .inv-dt {
    font-size: 14px !important;
  }

  .inv-num1,
  .inv-dt1 {
    font-size: 14px !important;
  }

  .radio-tabs-container {
    display: block;
  }


  .fortworad {
    text-align: center;
    display: flex;
  }

  .billpara {
    text-align: left;
    font-size: 20px;
  }
  .fortwoimg > .col > img{
    width: 9em !important;
  }
}

.pay-amt {
  display: none;
}


@media only screen and (max-width: 991px) {
  .res-card-container {
    margin-bottom: 2em;
  }
  .card-container {
    margin-top: 1em;
  }

  .button-styledown-border {
    float: right;
  }

  .mblcardthank{
    margin-top: 2em !important;
  }

  .mblcard{
    margin-top: 5em !important;
  }

  .formobicard {
    margin-top: 6em !important;
  }

  .paid-card {
    margin-top: 6em !important;
  }

  .res-card-row {
    margin-bottom: 4em !important;
  }

  .credimage,
  .credimage1 {
    display: none;
  }

  .pay-amt {
    display: block;
  }

  .valuedo {
    font-size: 25px;
    text-align: left;

  }

  .order-md-2 {
    order: 1 !important;
  }

  .credimage1 {
    margin-left: 5px;
  }

  .ipad-container {
    max-width: 870px;
  }

  .ipad-res {
    margin-top: 5px;
  }
}

.space {
  margin-top: 30px;
}

.input-res {
  padding: 8px 50px 8px 8px !important;
}

.center-input {
  text-align: center;
}

.input-text {
  font-size: 14px;
  color: #64748B;
  padding: 8px;
}

.input-text:focus {
  color: #64748B !important;
  box-shadow: none !important;
  border-color: #D0D0D0 !important;
}

.input-text::placeholder {
  color: #D0D0D0 !important;
}

@media only screen and (min-width: 1400px) {
  .error-msg {
    font-size: 14px !important;
  }

  .res-card-row {
    margin: 0px -40px 6em -40px !important;
  }

  .res-card-container {
    width: 670px;
  }

  .radio-tab {
    font-size: 13px;
  }

  .signup-lab,
  .expiryMonth {
    font-size: 15px;
  }
}

@media only screen and (min-width: 992px) {
  .ipad-container {
    max-width: 1140px;
  }

}

@media only screen and (min-width: 1140px) {
  .ipad-container {
    max-width: 1140px;
  }

}

@media only screen and (max-width: 500px) {
  .img-res {
    width: 25px !important;
    height: 15px !important;
  }

  .oronetwo {
    padding: 8px 2px !important;
  }
}

@media only screen and (max-width: 400px) {
  .fortworad {
    padding-left: 10px;
    padding-right: 0px;

  }

  .radio-tab {
    padding: 8px 3px !important;
    gap: 0px !important;
    font-size: 11px !important;
  }

  .radio-tab input[type="radio"] {
    width: 14px;
    height: 14px;
  }

  .new-input-class:checked::after {
    width: 8px !important;
    height: 8px !important;
  }
}

#myCheckbox {
  margin-top: 9px !important;
}

/* 
@media only screen and (max-width: 365px) {
  #myCheckbox {
    margin-top: 30px !important;
  }
} */

@media only screen and (max-width: 575px) {
  .width-input {
    width: 70% !important;
  }

  .res-yr {
    margin-top: 39px !important;
  }

  .button-stylebac,
  .loginbut {
    font-size: 12px;
    padding: 10px 25px;
  }

  .button-stylepay {
    font-size: 12px;
    padding: 10px 25px !important;
  }

  /* .radio-tab input[type="radio"] {
    width: auto;
  } */

  .radio-tab {
    padding: 10px;
  }

  .mbl-ress {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }

  .order-md-2 {
    order: 1 !important;
  }

  .button-styledown-border {
    float: left !important;
  }

  .res {
    margin-top: 10px !important;
  }

  .oronetwo {
    padding: 8px 4px;
  }
}


.new-input-class {

  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
  -ms-appearance: none;
  display: inline-block;
  vertical-align: middle;
  position: relative;
  outline: none;
  margin-right: 5px;
  width: 1em;
  height: 1em;
  border-radius: 50%;
  border: 1px solid #003876;
  background-color: #fff;
  cursor: pointer;
}

.new-input-class:checked {

  border-color: #003876;
  background-color: #fff;
}

.new-input-class:checked::after {

  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 11px;
  height: 11px;
  border-radius: 50%;
  background-color: #003876;
}

input[type=radio]:focus {
  outline: none !important;
}

.new-input-class:disabled {

  cursor: not-allowed;
  opacity: 0.6;
}

.new-label-class input {
  margin: 0px 7px 0 !important;
}


/* input[type="checkbox"] {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  width: 20px;
  height: 20px;
  border: 2px solid #003876;
  border-radius: 4px;
  outline: none;
  cursor: pointer;
  position: relative;
} */

/* 
input[type="checkbox"]::before {
  content: "\2714";

  font-size: 12px;
  color: transparent;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: color 0.3s;
} */


/* input[type="checkbox"]:checked::before {
  color: #003876 !important;

} */

/* 
label {
  margin-left: 5px;
} */

/* Hide the default checkbox */
/* input[type="checkbox"] {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  width: 20px;
  height: 20px;
  border: 2px solid #003876;
  border-radius: 4px;
  outline: none;
  cursor: pointer;
  position: relative;
} */

/* Create a custom symbol for "correct" using pseudo-elements */
/* input[type="checkbox"]::before {
  content: "\2713";
  font-size: 16px;
  font-weight: bold;
  color: transparent;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: color 0.3s;
} */

/* Style the symbol and background when checkbox is checked */
input[type="checkbox"]:checked::before {
  color: #003876;
  /* Custom color for the checkmark */
  /* Custom background color when checked */
}

/* Adjust the appearance of the label text */
label {
  color: #555;
}

.error-msg {
  font-size: 12px;
  color: red;
  margin-top: 3px;
  margin-bottom: 0px;
  display: block;
}

/* .delete-confirmation-popup {
  position: absolute;
  top: 18%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  padding: 20px;
  border: 1px solid #ccc;
  transition: transform 0.3s ease-in-out;
  border-radius: 5px;
  width: 25%;
} */

.shake {
  animation: shake 1s;
}

@keyframes shake {
  0% {
    transform: translateX(0);
  }

  25% {
    transform: translateX(-10px);
  }

  50% {
    transform: translateX(10px);
  }

  75% {
    transform: translateX(-10px);
  }

  100% {
    transform: translateX(0);
  }
}


.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
}

.Cancel-button {
  border: 1px solid gray;
  padding: 9px 25px;
  color: gray;
  font-weight: 500;
}

.Delete-button {
  border: 1px solid #ff3030;
  padding: 9px 25px;
  margin-left: 10px;
  background-color: #ff3030;
  color: #fff;
  font-weight: 500;

}

.Delete-button:hover {
  border: 1px solid #ff3030;
  padding: 9px 25px;
  margin-left: 10px;
  background-color: #ff3030;
  color: #fff;
  font-weight: 500;
}

.Cancel-button:hover {
  border: 1px solid gray;
  padding: 9px 25px;
  color: gray;
  font-weight: 500;
}

.button-sec {
  text-align: end;
}

/* @media (max-width: 1400px) {
  .delete-confirmation-popup {
    width: 40% !important;
  }
} */
@media (max-width: 767px) {
  .button-sec {
    text-align: center !important;
  }
}

@media (max-width: 992px) {
  .delete-confirmation-popup {
    width: 50% !important;
    /* top: 50%; */
  }
}

@media (max-width: 600px) {
  .delete-confirmation-popup {
    width: 80% !important;
    /* top: 50%; */
  }
}

.delete-confirmation-popup {
  margin: 70px auto;
  padding: 20px;
  background: #fff;
  border-radius: 5px;
  width: 30%;
  position: relative;
  /* transition: all 5s ease-in-out; */
}



.buttonload {
  background-color: #4CAF50;
  border: none;
  color: white;
  padding: 12px 24px;
  font-size: 16px;
  margin: 25px;
}

.fa {
  margin-left: -12px;
  margin-right: 8px;
}

.incorrect {
  border: 1px solid red;
}

/* 
.input-text fieldset{
  top:4px !important;
}
.input-text label{
top: 9px !important;
} */

.input-text input {
  padding: 5px 10px !important;
}

.billing-name .ant-form-item-required {
  color: #344767 !important;
  text-align: center;
  font-size: 14px !important;
  font-weight: 600;
  line-height: 150%;

}

@media only screen and (min-width: 1400px) {
  .billing-name .ant-form-item-required {
    font-size: 15px !important;
  }
}

.ok-btn:hover{
  background: #dddddd54 !important;
  color: #003876 !important;
}

.loginbtnpage{
  border-radius: 5px;
  border: none;
  color: #FFF;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding: 10px 50px;
  background: linear-gradient(185deg, #49A3F1 0%, #2C85EB 100%);
  box-shadow: 0px 1px 5px 0px rgba(52, 71, 103, 0.15), 0px 3px 1px -2px rgba(52, 71, 103, 0.20), 0px 3px 3px 0px rgba(52, 71, 103, 0.15);
}
.backtohome{
  border-radius: 5px;
border: 1px solid #3C96EE;
color: #3C96EE;
text-align: center;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: normal;
padding: 10px 20px;
}
.State label{
  color: #344767 !important;
  text-align: center !important;
  font-size: 15px !important;
  font-weight: 600 !important;
  line-height: 150% !important;

}

.Zipcode label{
  color: #344767 !important;
  text-align: center !important;
  font-size: 15px !important;
  font-weight: 600 !important;
  line-height: 150% !important; 
}

.City label{
  color: #344767 !important;
  text-align: center !important;
  font-size: 15px !important;
  font-weight: 600 !important;
  line-height: 150% !important;
}

.Billing_Address label{
  color: #344767 !important;
  text-align: center !important;
  font-size: 15px !important;
  font-weight: 600 !important;
  line-height: 150% !important;
}

.accountstyle{
  color: #8D8D94;
font-size: 15px;
font-style: normal;
font-weight: 500;
line-height: 19.2px; /* 128% */
letter-spacing: 0.129px;
text-transform: capitalize;
margin-bottom: 0;
}
.res-card-container {
  margin-bottom: 5em;
}


@media only screen and (max-width: 480px){
  .Toastify__toast-container {
    width: max-content !important;
    left: auto !important;
    top: 1em !important;
    right: 1em !important;

}
}
.css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root.Mui-checked{
  color: #437EB8 !important;
}
.MuiButtonBase-root{
  padding: 0px !important;
}
.addon-grid{
  display: grid;
  justify-content: center;
  width: 981px;
}
.addon-section{
  background-color: #fff;
  position: relative;
  top: 6em;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0px 4px 6.3px -1px rgba(0, 0, 0, 0.10), 0px 2px 4px -1px rgba(0, 0, 0, 0.06);
}
@media only screen and (max-width: 1024px) {
  .addon-grid{
    width: 90% !important;
  }

}